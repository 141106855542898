import { useEffect, useState, useRef } from "react"
import * as emailValidator from "email-validator"
import { NewsletterAPI } from "../../../apis/NewsletterAPI"
import Image from "next/image"
import newsletterLeftImage from "../../assets/images/giocatore1flipped.png"

export const Newsletter = ({ footer }: { footer?: boolean }) => {
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [privacy, setPrivacy] = useState<boolean>(false)
  const [promotions, setPromotions] = useState<boolean>(false)
  const [marketing, setMarketing] = useState<boolean>(false)
  const [completed, setCompleted] = useState<boolean>(false)
  const [waitingForResult, setWaitingForResult] = useState<boolean>(false)
  const [result, setResult] = useState<boolean>(false)
  const [horizontalLayout, setHorizontalLayout] = useState<boolean>(false)

  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleContainerWidth = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth
        if (containerWidth <= 700) {
          setHorizontalLayout(false)
        } else {
          setHorizontalLayout(true)
        }
      }
    }
    window.addEventListener("resize", handleContainerWidth)
    handleContainerWidth()
    return () => {
      window.removeEventListener("resize", handleContainerWidth)
    }
  }, [])

  const sendClick = () => {
    if (!name || !privacy || !emailValidator.validate(email)) {
      setShowValidationErrors(true)

      return null
    } else {
      setWaitingForResult(true)

      NewsletterAPI.create({
        email: email.trim(),
        name: name.trim(),
        promotions: promotions,
        privacy: privacy,
        marketing: marketing,
      })
        .then(() => {
          setResult(true)
        })
        .catch(() => {
          setResult(false)
        })
        .finally(() => {
          setCompleted(true)
          setWaitingForResult(false)
        })
    }

    return null
  }

  return (
    <div
      ref={containerRef}
      className={
        "hs-grid--item hs-grid--item hs-grid--item-4 hs-grid--item-m-12 hs-border-div hs-block marginBottomTen newsletterMainContainer " +
        (horizontalLayout ? "horizontal" : "")
      }
    >
      {horizontalLayout ? (
        <div className="newsletterHeaderContainer">
          <div className="newsletterHeaderImageContainer">
            <Image src={newsletterLeftImage} width={345} height={280} alt="giocatore" />
          </div>
          <div className="newsletterTitleAndInfoContainer">
            <div className="newsletterHeaderTitleContainer ">
              <h2 className="newsletterHeaderTitle">
                NEWSLETTER DI <strong>CALCIO.COM</strong>
              </h2>
            </div>
            <div className="newsletterHeaderInfoContainer">
              <div className="newsletterHeaderInfo">Iscriviti subito per restare sempre aggiornato</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="newsletterHeaderContainer">
          <div className="newsletterHeaderTitleContainer">
            <h2 className="newsletterHeaderTitle">
              NEWSLETTER DI <strong>CALCIO.COM</strong>
            </h2>
          </div>
          <div className="newsletterHeaderInfoContainer">
            <div className="newsletterHeaderInfo textac" style={{ textAlign: "center" }}>
              Iscriviti subito per restare sempre aggiornato
            </div>
            {/* <div className="newsletterHeaderImageContainer">
              <Image src={newsletterRightImage} width={140} height={155} alt="giocatore" />
            </div> */}
          </div>
        </div>
      )}
      <div className="newsletterBodyMainContainer">
        <div
          className={
            "hs-widget-inputs-div hs-widget-newsletter-body" + (footer ? " hs-widget-newsletter-body-footer" : "")
          }
        >
          {horizontalLayout ? (
            <div className="newsletterInputsContainerHorizontal">
              <div className="newsletterNameInputContainer">
                <div
                  className={
                    "hs-widget-input-div-name newsletterNameInput" + (footer ? " hs-widget-input-footer-div" : "")
                  }
                >
                  <input
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => {
                      setCompleted(false)
                      setShowValidationErrors(false)
                      setName(e.target.value)
                    }}
                  />
                </div>
                {showValidationErrors && !name && (
                  <span className="inputValidationErrors">{"* Campo obbligatorio"}</span>
                )}
              </div>

              <div className="newsletterNameInputContainer">
                <div
                  className={
                    "hs-widget-input-div-name newsletterEmailInput" + (footer ? " hs-widget-input-footer-div" : "")
                  }
                >
                  <span className="hs-widget-label-div hs-newsletter-name"></span>
                  <input
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => {
                      setCompleted(false)
                      setShowValidationErrors(false)
                      setEmail(e.target.value)
                    }}
                  />
                </div>
                {showValidationErrors && !email && (
                  <span className="inputValidationErrors">{"* Campo obbligatorio"}</span>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="newsletterNameInputContainer">
                <div
                  className={
                    "hs-widget-input-div-name newsletterNameInput" + (footer ? " hs-widget-input-footer-div" : "")
                  }
                >
                  <input
                    placeholder="Nome"
                    value={name}
                    onChange={(e) => {
                      setCompleted(false)
                      setShowValidationErrors(false)
                      setName(e.target.value)
                    }}
                  />
                </div>
              </div>
              {showValidationErrors && !name && <span className="inputValidationErrors">{"* Campo obbligatorio"}</span>}
              <div className="newsletterNameInputContainer">
                <div
                  className={
                    "hs-widget-input-div-name newsletterEmailInput" + (footer ? " hs-widget-input-footer-div" : "")
                  }
                >
                  <span className="hs-widget-label-div hs-newsletter-name"></span>
                  <input
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => {
                      setCompleted(false)
                      setShowValidationErrors(false)
                      setEmail(e.target.value)
                    }}
                  />
                </div>
              </div>
              {showValidationErrors && !email && (
                <span className="inputValidationErrors">{"* Campo obbligatorio"}</span>
              )}
            </>
          )}

          {showValidationErrors && email && !emailValidator.validate(email) && (
            <span className="inputValidationErrors">{"Inserisci una email valida"}</span>
          )}
          <div className="newsletterChecksMainContainer">
            <div className="hs-widget-input-div">
              <div className="newsletterCheckBox">
                <input
                  type={"checkbox"}
                  checked={privacy}
                  onChange={(e) => {
                    setCompleted(false)
                    setShowValidationErrors(false)
                    setPrivacy(e.target.checked)
                  }}
                />
              </div>
              <div className="newsletterCheckBoxContent">
                <span className="hs-widget-input-value-div">
                  {"Dichiaro di aver preso visione e di aver ben compreso la "}
                  <a title="Privacy & Cookie Policy" href="/privacy/" target="_blank" className="newsletter-link">
                    Privacy & Cookie Policy
                  </a>
                  {" e desidero iscrivermi al servizio di newsletter di calcio.com"}
                </span>
              </div>
            </div>
            {showValidationErrors && !privacy && (
              <span className="inputValidationErrors">{"* Campo obbligatorio"}</span>
            )}
            <div className="hs-widget-input-div">
              <div className="newsletterCheckBox">
                <input
                  type={"checkbox"}
                  checked={promotions}
                  onChange={(e) => {
                    setCompleted(false)
                    setShowValidationErrors(false)
                    setPromotions(e.target.checked)
                  }}
                />
              </div>
              <div className="newsletterCheckBoxContent">
                <span className="hs-widget-input-value-div">
                  {"Desidero essere informato su promozioni e offerte di calcio.com"}
                </span>
              </div>
            </div>
            <div className="hs-widget-input-div">
              <div className="newsletterCheckBox">
                <input
                  type={"checkbox"}
                  checked={marketing}
                  onChange={(e) => {
                    setCompleted(false)
                    setShowValidationErrors(false)
                    setMarketing(e.target.checked)
                  }}
                />
              </div>
              <div className="newsletterCheckBoxContent">
                <span className="hs-widget-input-value-div">
                  {"Acconsento al trattamento dei dati personali per "}
                  <a title="Privacy & Cookie Policy" href="/privacy/" target="_blank" className="newsletter-link">
                    finalità di marketing di terzi
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={"hs-widget-newsletter-button-div" + (footer ? " hs-widget-newsletter-button-div-footer" : "")}>
          <div className="text-button-cc yellow">
            <button onClick={sendClick} disabled={waitingForResult}>
              {completed ? <span>Iscrizione completata con successo</span> : <span>Iscriviti alla newsletter</span>}
            </button>
          </div>
        </div>
      </div>
      <div className="newsletterFooterMainContainer"></div>
    </div>
  )
}
