import { calciocomApiFrontend } from "./axiosConfig"
import * as APIConstants from "../constants/apis"
import { NewsletterSubscriptionCreate } from "../types/newsletterSubscriptions"

export const NewsletterAPI = {
  create: async function (newsletterSubscription: NewsletterSubscriptionCreate) {
    await calciocomApiFrontend.request({
      url: APIConstants.CALCIO_COM_NEWSLETTER_V1_POST,
      method: APIConstants.POST,
      data: newsletterSubscription,
    })
  },
}
